import { lazy } from "react"

import { useTranslation } from "react-i18next"
import type { RouteObject } from "react-router"
import { Navigate } from "react-router"

import { ChangeEmail } from "~/features/change-email/ChangeEmail"
import { LoyaltyBenefitsModal } from "~/features/loyalty-benefits-modal/LoyaltyBenefitsModal"
import { RemoveAccount } from "~/features/remove-account/RemoveAccount"

import { routeMapper } from "~/helpers/route-mapper"

import { useBaseConfig } from "~/config/MyAccountConfigContext"

import { AuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { formatNestedPath } from "./formatNestedPath"

const DashboardPage = lazy(() => import("~/features/dashboard/page"))
const ProfilePage = lazy(() => import("~/pages/profile/index"))

const OptinsPage = lazy(() => import("~/features/optins/page"))

const LoyaltyPage = lazy(() => import("~/features/loyalty/page"))

const ChangePasswordPage = lazy(() => import("~/pages/profile/ChangePasswordPage"))

const PersonalInformationPage = lazy(() => import("~/pages/profile/PersonalInformationPage"))

const ReceiptDetailPage = lazy(() => import("~/pages/receipt/ReceiptDetailPage"))

const OrderDetailsPage = lazy(() => import("~/pages/order/OrderDetailsPage"))

const PurchaseDetailsPage = lazy(() => import("~/pages/purchase/PurchaseDetailsPage"))

const PurchasesPage = lazy(() => import("~/pages/PurchasesPage"))

const SavedConfigurationsPage = lazy(() => import("~/pages/SavedConfigurationsPage"))

export function useAuthenticatedRoutes(): RouteObject[] {
  const { t } = useTranslation("urls")
  const { country } = useBaseConfig()

  return [
    {
      element: <AuthenticatedLayout />,
      children: [
        {
          path: t("/myaccount"),
          element: <DashboardPage />,
          children: [
            {
              path: t("/loyalty-benefits"),
              element: <LoyaltyBenefitsModal />,
            },
          ],
        },
        {
          path: t("/profile"),
          children: [
            {
              index: true,
              element: <ProfilePage />,
            },
            {
              path: formatNestedPath(t("/profile/personal-information"), t("/profile")),
              element: <PersonalInformationPage />,
              children: [
                {
                  path: t("/profile/personal-information/change-email"),
                  element: <ChangeEmail />,
                },
                {
                  path: t("/profile/personal-information/delete"),
                  element: <RemoveAccount />,
                },
              ],
            },
            {
              path: formatNestedPath(t("/profile/change-password"), t("/profile")),
              element: <ChangePasswordPage />,
            },
            {
              path: formatNestedPath(t("/profile/newsletter"), t("/profile")),
              element: <OptinsPage />,
            },
          ],
        },
        {
          path: t("/loyalty"),
          element: <LoyaltyPage />,
        },
        {
          path: t("/saved-configurations"),
          element: <SavedConfigurationsPage />,
        },
        {
          path: t("/purchases"),
          element: <PurchasesPage />,
        },
        {
          path: t("/orders"),
          children: [
            {
              index: true,
              element: <Navigate replace to={t("/purchases")} />,
            },
            {
              path: ":code",
              element: <OrderDetailsPage />,
            },
          ],
        },
        {
          path: `${t("/purchases")}/:code`,
          element: <PurchaseDetailsPage />,
        },
        {
          path: `${t("/receipts")}`,
          element: <Navigate to={t("/purchases")} />,
        },
        {
          path: `${t("/receipts")}/:receiptId`,
          element: <ReceiptDetailPage />,
        },
        {
          path: t("/remove-account"),
          element: <Navigate replace to={t("/profile/personal-information")} />,
        },
        {
          path: t("/profile/loyaltycards"),
          element: <Navigate replace to={t("/loyalty")} />,
        },
        {
          path: t("/loyaltyMutations"),
          element: <Navigate replace to={t("/loyalty")} />,
        },

        // Redirects for guest mode routes
        {
          path: t("/login"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
        {
          path: t("/sign-up"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
        {
          path: t("/forgot-password"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
        {
          path: t("/profile/newsletter"),
          element: <Navigate replace to={t("/myaccount")} />,
        },
      ].map((route) => routeMapper(country, route)),
    },
  ]
}
